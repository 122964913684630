/* navbar.css */

/* Light mode styles */
.navbar-container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff; /* Light background color */
}

.Logoo {
  width: 150px;
  height: 150px;
  margin-right: 120px;
  margin-bottom: 30px;
  border-radius: 50%; /* Make the image a circle */
  overflow: hidden; /* Ensure no part of the image overflows */
  object-fit: cover;
}
.navbar-button {
  margin-right: 50px;
  border-radius: 8px;
  background-color: #4caf50; /* Light button background color */
  color: #ffffff; /* Light button text color */
}

.drawer-paper {
  width: 250px;
  background-color: #f0f0f0; /* Light drawer background color */
}

.list {
  padding: 30px;
}

.list-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.list-item:hover {
  background-color: #e0e0e0; /* Light item hover background color */
}

.list-item img {
  margin-right: 10px;
}

.list-item-text {
  margin-left: 10px;
}

/* .logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  } */

/* Dark mode styles */
.dark-mode .navbar-container {
  background-color: #333333; /* Dark background color */
}

.dark-mode .navbar-button {
  background-color: #212121; /* Dark button background color */
  color: #ffffff; /* Dark button text color */
}

.dark-mode .drawer-paper {
  background-color: #212121; /* Dark drawer background color */
}

.dark-mode .list-item:hover {
  background-color: #424242; /* Dark item hover background color */
}
.navbar-info {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 20px 0; /* Add some margin for spacing */
  text-align: left; /* Align text to the left */
}

.navbar-info li {
  font-size: 14px; /* Adjust font size */
  color: #333; /* Text color */
  margin: 5px 0; /* Add some margin between list items */
  display: flex; /* Add flex to align items horizontally */
  align-items: center; /* Center items vertically */
  margin-right: 200px;
}

.navbar-info li:first-child {
  font-weight: bold; /* Make the first list item bold */
  font-size: 16px; /* Increase font size for the first list item */
  color: #0f0f0f; /* Change color for the first list item */
  margin-bottom: 10px; /* Add more space below the first list item */
  margin-right: 210px;
}

.navbar-info a {
  color: #4a412a; /* Link color */
  text-decoration: none; /* Remove underline from links */
  font-weight: bold;
  font-size: 15;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.navbar-info a:hover {
  text-decoration: underline; /* Add underline on hover */
}
