.login-form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #e9ecef;
}
.allForm {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
.upload-label {
  display: block;
  width: 30%;
  margin: 10px auto;
}
.upload-label {
  display: block;
  width: 30%;
  margin: 10px auto;
}
.centered-form-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers horizontally */
  justify-content: center; /* Centers vertically */
  width: 100%; /* Adjust the width as necessary */
  height: 100%; /* Adjust the height as necessary */
  text-align: center; /* Align the text to center */
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.NameAdders {
  display: flex;
  justify-content: center;
  gap: 80px;
  border-radius: 4px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin-bottom: 10px;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  display: block;
}
.ButtonName {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px;
  display: inline-block;
  text-align: center;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.OnButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-buttons-container {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}
/* Style for Form Item with Submit and Reset buttons */
/* Style for Form Item with Submit and Reset buttons */
.form-buttons-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Align the buttons vertically */
  gap: 10px; /* Space between the buttons */
  margin-top: 20px; /* Space above the buttons */
}

.form-buttons-container .ant-btn-primary {
  width: 120px; /* Set a consistent width for buttons */
  padding: 10px; /* Adjust button padding */
  border-radius: 4px; /* Slightly round the corners */
  font-weight: bold;
  font-size: 16px;
}

.form-buttons-container .ant-btn-danger {
  background-color: #ff4d4f; /* Red color for reset */
  border-color: #ff4d4f; /* Match border with the button */
}

.form-buttons-container .ant-btn-danger:hover {
  background-color: #ff7875; /* Hover effect for reset */
  border-color: #ff7875;
}
