/* Global styles for the login container */
.login-container {
  display: flex;
  flex-direction: column; /* Stack image and form vertically */
  justify-content: flex-start; /* Align items from the top */
  align-items: center; /* Center items horizontally */
  height: 100vh;
  background-color: #ffffff;
}
/* Style for the header image */
.login-header {
  margin-bottom: 20px; /* Add some space between the image and the form */
}
.header-image {
  margin-top: 10px;
  max-width: 150px; /* Reduce max-width for a more compact appearance */
  height: auto; /* Keep the height automatic for proportional scaling */
  object-fit: contain; /* Maintain the aspect ratio of the image */
  border-radius: 10px; /* Change to a smaller border radius for a subtle rounding effect */
  margin-bottom: 20px; /* Add margin below the image for spacing from the form */
  overflow: hidden; /* Ensure no part of the image overflows */
  object-fit: cover;
}

/* Style for the form container */
.login-form-container {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Basic form adjustments for better look */
.login-form .form-label {
  font-weight: bold;
}

/* Button styling */
.login-form .ant-btn {
  width: 100%;
  height: 40px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .login-container {
    padding: 20px;
  }

  .login-form-container {
    max-width: 300px;
    padding: 15px;
  }

  .login-header {
    margin-bottom: 15px;
  }

  .header-image {
    max-width: 150px;
  }

  .login-form .form-label {
    font-size: 14px;
  }

  .login-form .ant-btn {
    height: 36px;
  }
}

/* Media query for laptop screens */
@media (min-width: 1024px) {
  .login-form-container {
    max-width: 450px;
    padding: 30px;
  }

  .header-image {
    max-width: 250px;
  }

  .login-form .form-label {
    font-size: 16px;
  }

  .login-form .ant-btn {
    height: 45px;
  }
}
