/* order.css */

.order-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.order-details-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.order-details-content {
  line-height: 1.6;
  font-size: 16px;
  font-weight: bold;
}

.detail-label {
  font-weight: bold;
  margin-right: 8px;
}

List.Item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.product-info {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-left: 16px; /* Adjust the margin as needed */
}

span.error-message {
  color: red;
}

/* Add more styles as needed */
