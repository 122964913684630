.login-form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #e9ecef; /* Changed background color for a softer tone */
}
.allForm {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.upload-label {
  display: block;
  width: 30%;
  margin: 10px auto;
}
.upload-label {
  display: block;
  width: 30%;
  margin: 10px auto;
}
.centered-form-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers horizontally */
  justify-content: center; /* Centers vertically */
  width: 100%; /* Adjust the width as necessary */
  height: 100%; /* Adjust the height as necessary */
  text-align: center; /* Align the text to center */
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.centered-form-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers horizontally */
  justify-content: center; /* Centers vertically */
  width: 100%; /* Adjust the width as necessary */
  height: 100%; /* Adjust the height as necessary */
  text-align: center; /* Align the text to center */
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-button {
  display: inline-block;
  width: 100%;
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}
.NameAdders {
  display: flex;
  justify-content: center;
  gap: 80px;
  border-radius: 4px; /* Corrected syntax */
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin-bottom: 10px;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  display: block;
}
.ButtonName {
  width: 200px; /* Corrected syntax */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px;
  display: inline-block;
  text-align: center;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.OnButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Default styles for the buttons */
/* Default styles for the buttons */
/* Add these styles to your existing CSS file */

.form-buttons-container {
  display: flex; /* Use flexbox for horizontal alignment */
  flex-direction: row !important;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Add some space between the buttons */
  margin: 10px 0; /* Add vertical margin to create space around the buttons */
}
