/* Scroll-to-top button styling */
.scroll-to-top-button {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  background-color: yellow !important;
  color: black !important;
  border: none !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  z-index: 1000 !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.scroll-to-top-button:hover {
  background-color: orange !important;
  color: white !important;
}

/* Navigate-to-new-page button styling */
.navigate-to-new-page-button {
  position: fixed !important;
  bottom: 20px !important;
  right: 90px !important; /* Increased spacing to avoid overlapping with the scroll-to-top button */
  background-color: rgb(9, 184, 248) !important;
  color: white !important;
  border: none !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  z-index: 1000 !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.navigate-to-new-page-button:hover {
  background-color: rgb(6, 150, 200) !important; /* Darkened hover effect */
  color: white !important;
}
