.super {
  padding: 0;
  margin: 0;
}

.list-container {
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center; /* Center items on smaller screens */
  gap: 10px;
}
.list-item {
  margin-bottom: 10px;
  background-color: #242323;
  box-shadow: 4px 8px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  padding: 40px;
  margin: 10px 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.list-item:hover {
  transform: scale(1.05);
  box-shadow: 6px 12px 10px rgba(0, 0, 0, 0.2);
  background-color: #242323;
}

.item-content {
  display: block;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin-right: 13px;
}

.user,
.number {
  display: block;
  font-size: 26px;
  margin-bottom: 5px;
  color: #f3f7fa;
}

.number {
  color: #fdfefe;
}

.pinned-products {
  background: #f6e96b;
  color: black;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px 100px / 120px;
}

.pinned-slider {
  background: #0d7c66;
  color: rgb(253, 250, 250);
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px 100px / 120px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .list-item {
    margin: 10px 20px; /* Adjust margin for smaller screens */
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .user,
  .number {
    font-size: 20px; /* Smaller font size for mobile */
  }

  .dashboard-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    height: auto; /* Adjust height */
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .list-item {
    margin: 10px 10px; /* Further adjust margins for very small screens */
    padding: 15px; /* Reduce padding for very small screens */
  }
}
